<template>
  <div class="group-dialog">
    <b-modal id="modalCreateGroup" modal-class="dialog-1200 custom-dialog modal-create-group" centered ok-only
      ok-title="Añadir" ok-variant="warning"
      @close="confirmClose" @ok="ok" no-close-on-esc no-close-on-backdrop
      title="Grupo" style="min-height: 600px">
      <form-group :modified.sync="modified" :item="variable" :data="group" origin="rule" @send="sendDataGroup" ref="formGroup"></form-group>
    </b-modal>
  </div>
</template>
<script>
import FormGroup from '../../groups/create/FormGroup.vue'
export default {
  components: {FormGroup},
  props: ['active', 'sendGroup', 'variable', 'group'],
  data () {
    return {
      keyFormRender: 0,
      modified: false,
      newGroup: {}
    }
  },
  watch: {
    modified () {
    }
  },
  update () {
  },
  methods: {
    ok (e) {
      e.preventDefault()
      this.$refs.formGroup.callSubmitFormRender()
    },
    confirmClose (bvModalEvt) {
      if (this.modified) {
        bvModalEvt.preventDefault()
        this.$confirm('¿Está seguro que desea salir sin guardar los cambios?', (_) => this.$bvModal.hide('modalCreateGroup'), 'Tienes cambios sin guardar', 'Si, quiero salir', 'Seguir modificando')
      } else {
        this.$bvModal.hide('modalCreateGroup')
      }
    },
    sendDataGroup (data) {
      this.$emit('sendGroup', data)
    }
  }
}
</script>
<style>
</style>