export default [
  {id: 'in', text: 'Incluye', isArray: true},
  {id: 'not-in', text: 'No incluye', isArray: true},
  {id: 'in-range', text: 'Incluye', isArray: false},
  {id: 'out-range', text: 'No incluye', isArray: false},
  {id: 'includes', text: 'Contiene', isArray: true},
  {id: '<', text: 'Menor (<)', isArray: false},
  {id: '>', text: 'Mayor (>)', isArray: false},
  {id: '<=', text: 'Menor o igual (<=)', isArray: false},
  {id: '>=', text: 'Mayor o igual (>=)', isArray: false},
  {id: '==', text: 'Igual (==)', isArray: false},
  {id: 'is', text: 'Igual', isArray: false},
  {id: 'is-null', text: 'El valor es nulo', isArray: false},
  {id: 'in-or', text: 'Incluye al menos uno', isArray: false}
]