<template>
  <div>
    <b-modal id="modalKeyWords" class="dialog-600 custom-dialog" modal-class="modal-success" 
      ok-only ok-variant="outline-dark" :ok-title="$t('Cerrar')" title="Detalle regla">
      <table-render :schema="schema" :rows="rows" :actions="actions" ></table-render>
    </b-modal>
  </div>
</template>
<script>

export default {
  props: ['active', 'addWord'],
  data () {
    return {
      schema: [],
      rows: [],
      actions: []
    }
  },
  mounted () {
    this.schema = [
      {label: 'Key', key: 'key', sortable: true},
      {label: 'Descripción', key: 'description', sortable: true},
      {label: 'Añadir', key: 'actions', class: 'text-center'}
    ]
    this.rows = [
      { id: 1, key: 'tracking_number', description: 'Numero de OT'},
      { id: 2, key: 'client_name', description: 'Nombre de cliente'},
      { id: 3, key: 'carrier_name', description: 'Nombre de courier'},
      { id: 4, key: 'current_delivery_status_date(REEMPLACE_POR_NUMERO_DE_DIAS)', description: 'Cálculo de fechas para estado actual de envío'},
      { id: 5, key: 'current_type_date(REEMPLACE_POR_NUMERO_DE_DIAS)', description: 'Cálculo de fechas para asunto de ticket actual'},
      { id: 6, key: 'current_status_date(REEMPLACE_POR_NUMERO_DE_DIAS)', description: 'Cálculo de fechas para estado actual de ticket'},
      { id: 7, key: 'current_date_process(REEMPLACE_POR_NUMERO_DE_DIAS)', description: 'Cálculo de fecha según la fecha actual'},
      { id: 8, key: 'first_date_status_carrier(REEMPLACE_POR_NUMERO_DE_DIAS)', description: 'Cálculo de fecha según el primer estado del courier'},
      { id: 9, key: 'date_ready_for_dispatch(REEMPLACE_POR_NUMERO_DE_DIAS)', description: 'Cálculo de fecha según el estado listo para despacho'},
      { id: 10, key: 'date_first_problem(REEMPLACE_POR_NUMERO_DE_DIAS)', description: 'Cálculo de fecha según la primera incidencia del courier'},
      { id: 11, key: 'transit_days', description: 'Dias de transito'},
      { id: 12, key: 'delivery_status_comment', description: 'Comentario del ultimo estado del envío'},
      { id: 13, key: 'delivery_status_name', description: 'Nombre del estado actual del envío'},
      { id: 14, key: 'delivery_id', description: 'id del envío'}
    ]
    this.actions = [
      {action: id => this.addKeyWord(id), icon: 'CopyIcon', color:'success', text: 'Añadir'}
    ]
  },
  methods: {
    addKeyWord (_id) {
      this.$emit('addWord', this.rows.filter(({id}) => parseInt(id) === parseInt(_id))[0])
    }
  }
}
</script>
<style>
  
</style>