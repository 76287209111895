<template>
  <div class="conditions-list">
    <div v-show="ready">
      <div v-for="(condition, index) in conditions" :key="index" class="mb-2 mt-2"
        :class="[
          {'success-marker': !!editable && condition._new && !condition._edited && !condition._deleted}, 
          {'warning-marker': !!editable && condition._edited && !condition._deleted},
          {'danger-marker': !!editable && condition._deleted},
          {'element-condition': !!editable}
        ]">
        <div class="header-condition">
          <b>{{condition.variable.text ? condition.variable.text : condition.variable.title }} </b>
          <span v-if="!!condition.last_user && !!condition.last_user.id && condition.last_user.id !== ''"> modificados por <b>{{condition.last_user.textLastUser}} </b></span>
          <em>{{condition.operator.textOperator}}</em> 
          <span v-if="!!editable && !condition.editing && !condition._deleted" class="header-button"> &nbsp; <b-button variant="line-warning" class="p-0" @click="$emit('editCondition', index)">Editar</b-button></span>
          <span v-if="!!editable && !condition.editing && !condition._deleted" class="header-button"> &nbsp; <b-button variant="line-danger" class="p-0" @click="$emit('removeCondition', index)">Eliminar</b-button></span>
          <span v-if="!!editable && condition.editing && !condition._deleted" class="header-button"> &nbsp; <b-button variant="line-warning" class="p-0" @click="$emit('cancelEdit', index)">Cancelar edición</b-button></span>
          <span v-if="!!editable && condition._deleted" class="header-button"> &nbsp; <b-button variant="line-warning" class="p-0" @click="$emit('cancelDelete', index)"><font-awesome-icon class="mt-auto mb-auto" :icon="['fas', 'undo']"/> Deshacer</b-button></span>
        </div>
        <div>
          <div v-if="condition.valueGroup.typeof_value === 'array'" class="single-condition">
            <div class="value-condition" :key="value" v-for="value of condition.valueGroup.text_values">{{value}}</div>
          </div>
          <div v-else-if="condition.valueGroup.typeof_value === 'range'" class="single-condition">
            <div class="value-condition">
              Desde <b>{{condition.valueGroup.values.from}}</b>{{ condition.valueGroup.values.include_from ? ' (Incluido)' : '' }} hasta <b>{{condition.valueGroup.values.to}}</b>{{ condition.valueGroup.values.include_to ? ' (Incluido)' : '' }}
            </div>
          </div>
          <div v-else-if="condition.valueGroup.typeof_value === 'option'" class="single-condition">
            <div class="value-condition">{{condition.valueGroup.values[0].text || condition.valueGroup.values[0].id }}</div>
          </div>
          <div v-else-if="condition.valueGroup.typeof_value === 'nullable'" class="single-condition">
            <div class="value-condition">Solo valores nulos o vacíos</div>
          </div>
          <div v-else-if="condition.valueGroup.typeof_value === 'number'" class="single-condition">
            <div class="value-condition">{{condition.valueGroup.values[0]}}</div>
          </div>
          <div v-else><pre>{{condition.valueGroup.values}}</pre></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['conditions', 'editable', 'editCondition', 'removeCondition', 'cancelEdit', 'cancelDelete', 'ready'],
  watch: {
    conditions: {
      handler () {
      },
      deep: true
    }
  }
}
</script>
<style lang="scss">
.single-condition {
  .value-condition {
    border: 1px solid #d4d4d4;
    background: #f3f3f3;
    padding: 2px 5px;
    margin-top: 10px;
    margin-right: 5px;
    border-radius: 5px;
    font-size: 12px;
    color: gray;
    display:inline-block;
  }
}
.element-condition{
  border-left: 5px solid transparent;
  transition: all 0.4s ease;
  background: #eee;
  border-radius: 5px;
  padding: 10px;
}
.header-condition{
  > span.header-button {
    float: right;
  }
}
</style>