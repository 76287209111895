<template>
    <div id="add-condition" class="vs-con-loading__container">
      <div class="mt-1 combination" id="form-group-combination" title="Condición">
        <h4 class="mb-1">{{ title }}</h4>
        <form-render id="condition-form" ref="formRenderConditionForm" :key="keyFormRender" :fields="fields" :form.sync="form" @send="$emit('addCombination', form)">
          <div slot="editingSlot">
            <em>Variable: {{ form && form.variable && form.variable.title ? form.variable.title : '' }}</em>
          </div>
          <div slot="personalize">
            <b-badge :variant="!form.variable ? 'secondary' : 'light'" class="cursor-pointer" v-b-modal="form.variable ? 'modalCreateGroup' : ''">Personalizar</b-badge>
          </div>
        </form-render>
          <div v-if="isValidSelection && !loading.condition">
            <conditions-list :key="keyConditionList" :conditions="[form]" :editable="false" :ready="true"/>
          </div>
          <div v-if="!isValidSelection && loading.condition && form.operator">
            <b-skeleton class="mb-1 mt-1" width="300px"/>
            <div>
              <b-skeleton class="mr-1 float-left" width="130px"/>
              <b-skeleton class="mr-1 float-left" width="180px"/>
              <b-skeleton class="mr-1 float-left" width="110px"/>
              <b-skeleton class="mr-1 float-left" width="90px"/>
              <b-skeleton class="mr-1 float-left" width="100px"/>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <b-button variant="outline-light" class="push-right mt-1" @click="$refs.formRenderConditionForm.checkForm()">
                <feather-icon icon="PlusIcon"/> Agregar condición
              </b-button>
            </div>
          </div>
      </div>
      <modal-create-group :variable="form.variable" :group="valueGroup" @sendGroup="setNewDefinition"></modal-create-group>
    </div>
</template>
<script>

import ModalCreateGroup from './ModalCreateGroup.vue'
import ConditionsList from './ConditionsList.vue'
// import cards from '@/const-data/groups/cards'
import comunas from '@/const-data/comunas.json'
import lastUserList from '@/const-data/groups/last_user'
import operators from '@/const-data/groups/operators'
import TicketManagementService from '../../ticketManagement.service.js'
export default {
  props: ['form', 'addCombination', 'editingCondition', 'cards', 'operatorEdit'],
  components: {ModalCreateGroup, ConditionsList},
  data () {
    return {
      valueGroup: {},
      fields: [],
      title: 'Nueva condición',
      optionsType: [{id: 'new', text: 'Nueva definción de grupo'}, {id:'old', text: 'Grupo existente'}],
      optionsGroup: [],
      type: null,
      keyFormRender: 1,
      keyConditionList: 1,
      openModal: false,
      isValidSelection: false,
      disabledGroups: true,
      modifyGroup: {},
      prevGroup: {},
      mainClassField: 'col-sm-12 container-info',
      elementListLoaded: true,
      loading: {
        condition: false
      },
      optionsVariable: [],
      ticketManagementService: new TicketManagementService(this)
    }
  },
  watch: {
    form: {
      handler () {
        if (!!this.form.variable && this.fields[2].loaded !== this.form.variable.id) {
          this.getGroups(this.form.variable)
        }
        this.addExtraFieldVariable(this.form.variable)
        this.verifyValidSelection()

        if (!!this.form.valueGroup) {
          this.valueGroup = this.form.valueGroup
        }
        this.$emit('update:form', this.form)
      },
      deep: true
    },
    cards: {
      handler () {
        this.initializeForm()
      }
    },
    editingCondition () {
      if (this.editingCondition) {
        this.title = 'Modificar condición'
        const optionsOperator = this.setOptionsOperatorEditing(this.form.variable.operators)
        this.fields = [
          {name: 'editingSlot', useSlot: true, containerClass: `${this.mainClassField} col-md-12`},
          {fieldType: 'FieldSelect', name: 'operator', label: 'Operador', containerClass: `${this.mainClassField} col-md-2`, validation: 'required', clearable: false, dependency: 'variable', options: optionsOperator, change: this.selectOperator, searchOnType: { nChars: 999}},
          {fieldType: 'FieldSelect', name: 'group', label: 'Grupo', containerClass: `${this.mainClassField} col-md-10 `, validation: 'required', dependency: 'variable', options: [], change: this.selectGroup, searchOnType: { nChars: 999}},
          {name: 'personalize', useSlot: true, containerClass: 'personalize'}
        ]
        this.selectOperator('', this.operatorEdit.operator)
      } else {
        this.title = 'Nueva condición'
        this.fields = [
          {fieldType: 'FieldSelect', name: 'variable', label: 'Variable', containerClass: `${this.mainClassField} col-md-4`, validation: 'required', options: [], change: this.selectVariable, searchOnType: { nChars: 999}},
          {fieldType: 'FieldSelect', name: 'operator', label: 'Operador', containerClass: `${this.mainClassField} col-md-2`, validation: 'required', clearable: false, dependency: 'variable', options: [], change: this.selectOperator, searchOnType: { nChars: 999}},
          {fieldType: 'FieldSelect', name: 'group', label: 'Grupo', containerClass: `${this.mainClassField} col-md-6 `, validation: 'required', dependency: 'variable', options: [], change: this.selectGroup, searchOnType: { nChars: 999}},
          {name: 'personalize', useSlot: true, containerClass: 'personalize'}
        ]
      }
    }
  },
  beforeMount () {
  },
  mounted() {
    this.initializeForm()
  },
  beforeUpdate() {
    this.setOptionsVariable()
  },
  methods: {
    initializeForm () {
      this.fields = [
        {fieldType: 'FieldSelect', name: 'variable', label: 'Variable', containerClass: `${this.mainClassField} col-md-4`, validation: 'required', options: [], change: this.selectVariable, searchOnType: { nChars: 999}},
        {fieldType: 'FieldSelect', name: 'operator', label: 'Operador', containerClass: `${this.mainClassField} col-md-2`, validation: 'required', clearable: false, dependency: 'variable', options: [], change: this.selectOperator, searchOnType: { nChars: 999}},
        {fieldType: 'FieldSelect', name: 'group', label: 'Grupo', containerClass: `${this.mainClassField} col-md-6 `, validation: 'required', dependency: 'variable', options: [], change: this.selectGroup, searchOnType: { nChars: 999}},
        {name: 'personalize', useSlot: true, containerClass: 'personalize'}
      ]
    },
    setOptionsVariable () {
      if (this.cards) {
        this.optionsVariable = this.cards.filter(({parent}) => !!parent).map(card => {
          card.text = card.title
          return card
        })
        this.fields[0].options = this.optionsVariable
      }
    },
    openModalGroup () {
      this.openModal = true
    },
    setNewDefinition (group) {
      if (
        (!this.valueGroup && !!group && group.values) ||
        (JSON.stringify(this.valueGroup.values) !== JSON.stringify(group.values))
      ) {
        this.form.group = {id: '', text: 'Personalizado', values: group.values}
      }
      this.form.valueGroup = this.defineValueGroup(group.values, false)
      if (this.form.variable.type === 'list') {
        this.form.valueGroup.text_values = group[this.form.variable.id].map(({text}) => text)
      } else {
        this.form.valueGroup.text_values = group.values
      }
      this.$bvModal.hide('modalCreateGroup')
      this.disabledGroups = true
      this.keyConditionList++
    },
    defineValueGroup (values, loadValueList) {
      this.isValidSelection = false
      if (!!values) {
        const valueGroup = {
          ...this.form.variable,
          variable: this.form.variable.id || '',
          values
        }
        valueGroup.typeof_value = this.ticketManagementService.validateNullVariable(valueGroup)
        if (!loadValueList && !this.form.variable.type === 'list') valueGroup.text_values = values
        this.isValidSelection = true
        return valueGroup
      }
      return {}
    },
    
    getTextValues (condition) {
      if (!condition.valueGroup.text_values && condition.variable.type === 'list') {
        return this.getListGroup(condition)
      } else if (condition.variable.type !== 'list') {
        condition.valueGroup.text_values = condition.valueGroup.values
        return condition
      } else if (this.conditionsLoaded === this.conditions.length) {
        return condition
      }
    },
    getListGroup (condition) {
      if (condition.variable.fx) {
        this.loading.condition = true
        this.ticketManagementService.callService(condition.variable.fx, {limit:9999, page:1})
          .then(response => {
            const listValues = this.ticketManagementService.formatSelect(response.data, condition.variable.id)
            condition.valueGroup.text_values = condition.valueGroup.values.map(val => {
              const indexElement = listValues.findIndex(element => element.id === val)
              return indexElement !== -1 ? listValues[indexElement].text : val
            })
          })
          .catch(err => {
            console.error(err)
            this.$alert(`Ocurrió un problema al cargar la lista de ${condition.variable.title.toLowerCase()}`)
          })
          .finally(end => {
            this.loading.condition = false
            this.elementListLoaded = true
            this.verifyValidSelection()
            return condition
          })
      } else if (condition.variable.data) {
        const listValues = condition.variable.data
        condition.valueGroup.text_values = condition.valueGroup.values.map(val => {
          const indexElement = listValues.findIndex(element => element.id === val)
          return indexElement !== -1 ? listValues[indexElement].text : val
        })
      } else if (['commune', 'commune_drop'].includes(condition.variable.id)) {
        const listValues = this.ticketManagementService.formatSelect(comunas, condition.variable.id)
        condition.valueGroup.text_values = condition.valueGroup.values.map(val => {
          const indexElement = listValues.findIndex(element => element.id === val)
          return indexElement !== -1 ? listValues[indexElement].text : val
        })
      }
    },
    selectVariable (fieldName, value) {
      this.isValidSelection = false
      this.valueGroup = undefined
      this.form.operator = null
      this.form.group = null
      if (value) {
        this.form.last_user = lastUserList.filter(({id}) => id === '')[0]
        this.selectLastUser('last_user', this.form.last_user)
        this.disabledGroups = true
        this.fields[2].loaded = ''
        this.keyFormRender++
      }
    },
    addExtraFieldVariable (value) {
      if (this.fields.findIndex(({name}) => name === 'last_user') !== -1) {
        this.fields.splice(4, 1)
      }
      if (value && value.id && ['ticket_type', 'ticket_status'].includes(value.id)) {
        this.fields.splice(4, 0, 
          {fieldType: 'FieldSelect', name: 'last_user', label: 'Usuario de último cambio', containerClass: `${this.mainClassField} col-md-4`, validation: 'required', dependency: 'variable', change: this.selectLastUser, searchOnType: { nChars: 999},
            options: lastUserList, clearable: false
          }
        )
      }
      this.keyFormRender++
    },
    getGroups (value) {
      // this.$vs.loading({container: '#condition-form > #group', text: ''})
      this.setOptionsOperator(value.operators)
      const queryParams = {
        variable: value.id
      }
      this.ticketManagementService.callService('getAllGroups', queryParams)
        .then(response => {
          this.disabledGroups = false
          this.optionsGroup = response.data ? response.data.map(group => ({...group, text: group.name})) : []
          this.fields[2].options = this.optionsGroup
          this.fields[2].loaded = value.id
        })
        .catch(err => {
          this.optionsGroup = []
          this.fields[2].options = []
        })
        .finally(end => {
          this.keyConditionList++
          // this.$vs.loading.close('#group > .con-vs-loading')
        })
    },
    selectOperator (fieldName, operator) {
      if (this.form.operator) {
        this.form.operator.textOperator = operator ? this.ticketManagementService.getTextOperator(operator.id) : ''
      }
    },
    selectLastUser (fieldName, last_user) {
      this.form.last_user.textLastUser = last_user && last_user.id !== '' ? this.form.last_user.text.toLowerCase() : ''
    },
    selectGroup (fieldName, group) {
      this.valueGroup = group
      this.prevGroup = group
      this.form.valueGroup = {}
      if (group) {
        if (this.form.variable.type === 'list') this.elementListLoaded = false
        else  this.elementListLoaded = true
        this.form.valueGroup = this.defineValueGroup(group.values, true)
        this.form = {...this.form, ...this.getTextValues(this.form)}
      }
      this.keyConditionList++
    },
    setOptionsOperatorEditing (valueOptions) {
      let options = []
      valueOptions.forEach(element => {
        options = [...options, operators.filter(option => option.id === element)[0]]
      })
      return options
    },
    setOptionsOperator (valueOptions) {
      this.fields[1].options = this.setOptionsOperatorEditing(valueOptions)
      this.form.operator = [...this.fields[1].options][0]
    },
    verifyValidSelection () {
      this.isValidSelection = !!this.form.variable && !!this.form.operator && !!this.form.group && (this.elementListLoaded || (this.form.variable.data && this.form.variable.data.length > 0))
      this.keyFormRender++
    }
  }
}
</script>
<style lang="scss">
.personalize > * > * > .badge{
  position: absolute;
  left: calc(100% - 8.1rem);
}
.key-word > * > * > .badge{
  left: calc(100% - 9rem);
  position: absolute;
}
</style>