<template>
  <div id="form-rule" v-if="viewData">
    <b-card>
      <div class="exterior-button">
        <b-button class="push-right" variant="warning" @click="checkRule"><feather-icon icon="SaveIcon"/> {{isEdit ? 'Modificar regla' : 'Guardar'}}</b-button>
        <b-button v-if="isEdit" class="push-right mr-2" variant="outline-danger" @click="confirmDelete"><feather-icon icon="TrashIcon"/> Eliminar</b-button>
      </div>
      <b-alert v-if="isEdit && !loading" class="col-sm-12 mt-1 mb-2" variant="warning">
        <div class="alert-body">
          <span>Recuerde que los datos no serán modificados hasta que la información sea guardada al presionar el botón "Modificar regla"</span>
        </div>
      </b-alert>
      <div v-show="!loading">
        <form-render id="base-form" ref="formRenderRule" :key="keyFormRender" :fields="fieldsBase" :form.sync="form" @send="sendForm" :buttonSend="undefined" containerButtonsClass="col-sm-12" formClass="w-100">
          <div slot="key-word-slot">
            <b-badge variant="light" class="cursor-pointer" v-b-modal.modalKeyWords>Palabras clave</b-badge>
          </div> 
        </form-render>
        <form-render v-if="form.programmed_rule" id="freq-form" ref="formFreq" :key="`fq${keyFormRender}`" :fields="fieldsFreq" :form.sync="form2" @send="sendForm2"
          :buttonSend="buttonSend" containerButtonsClass="align-items-lg-center col-md-2 col-sm-12 d-flex">
        </form-render>
        <div class="vx-row" v-if="form.programmed_rule">
          <div class="vx-col w-full" v-if="chips.length > 0 && chips[0] !== ''">
            <b-badge variant="primary" v-for="(chip, index) in chips" :key="index" @click="removeChip(chip)">{{chip.country_id}} | {{chip.day}} {{chip.hour}} <b-button class="p-0 text-white" variant="link" >X</b-button></b-badge>
          </div>
        </div>
        <modal-key-words @addWord="addWordTextbox"></modal-key-words>
      </div>
      <div v-show="loading" class="skeleton-container">
        <b-skeleton height="35px"/>
        <b-skeleton height="80px" class="mt-1"/>
        <b-skeleton height="35px" width="60%" class="mt-1"/>
      </div>
      <div class="mt-1">
        <h4 class="mb-1" v-show="!loading">Lista de condiciones</h4>
        <div v-if="!loading">
          <b-alert :show="!ruleHasConditions() && !loading" variant="warning" v-height-fade fade>
            <div class="alert-body">
              <span>La regla no tiene condiciones vigentes</span>
            </div>
          </b-alert>
          <conditions-list :key="keyConditionList" :conditions.sync="conditions" :editable="true"  :ready="true"
          @editCondition="editCondition" @cancelEdit="cancelEdit" @removeCondition="removeCondition" @cancelDelete="cancelDelete"/>
        </div>
        <b-skeleton v-show="loading" class="mb-5" width="200px"/>
        <b-skeleton v-show="loading" height="35px"/>
      </div>
      <hr class="separator mt-1">
      <div class="mt-1">
        <condition v-show="!loading" :operatorEdit="operatorEdit" :form.sync="form" @addCombination="addCombination" :editingCondition="currentIndexGroup !== -1" :cards="optionsVariable" >
        </condition>
        <b-skeleton v-show="loading" class="mb-1" width="200px"/>
        <b-skeleton v-show="loading" height="35px"/>
      </div>
    </b-card>
    <div class="mb-5 inferior-button hidden">
      <b-button class="push-right" variant="warning" @click="checkRule"><feather-icon icon="SaveIcon"/> {{isEdit ? 'Modificar regla' : 'Guardar'}}</b-button>
    </div>
  </div>
</template>

<script>

import Condition from './Condition.vue'
import ConditionsList from './ConditionsList.vue'
import ModalKeyWords from './ModalKeyWords.vue'
import comunas from '@/const-data/comunas.json'
// import cards from '@/const-data/groups/cards'
import operators from '@/const-data/groups/operators'
import lastUserList from '@/const-data/groups/last_user'

import TicketManagementService from '../../ticketManagement.service.js'


export default {
  components: {Condition, ConditionsList, ModalKeyWords},
  name: 'Rules',
  props: ['text'],
  data () {
    return {
      ticketManagementService: new TicketManagementService(this),
      buttonSend: {text: 'Agregar', icon: '', color: 'success', class: 'mt-05'},
      fields: [],
      form: {},
      fieldsFreq: [],
      form2: {},
      chips: [],
      days: [
        { id: 0, text: 'Domingo'},
        { id: 1, text: 'Lunes'},
        { id: 2, text: 'Martes'},
        { id: 3, text: 'Miércoles'},
        { id: 4, text: 'Jueves'},
        { id: 5, text: 'Viernes'},
        { id: 6, text: 'Sabado'}
      ],
      hours: [],
      optionsCategory: [],
      optionsVariable: [],
      optionsOperator: operators,
      optionsGroup: [],
      ticketSubjects: [],
      ticketStatuses: [],
      ticketSubStatuses: [],
      cards: [],
      groups: [],
      keyFormRender: 0,
      keyConditionList: 0,
      mainClassField: 'vx-col w-full md:w-1/3 container-info',
      openModal: false,
      valueGroup: [],
      radioCondition: [],
      currentIndexGroup: -1,
      conditions: [],
      currentVariable: {},
      currentGroup: {},
      isEdit: this.$route.params && this.$route.params.id,
      loading: true,
      loadingSave: false,
      countries: [],
      conditionsLoaded: 0,
      optionsSuperRule: [{ id: 'super', text: 'Al marcar esta casilla, la regla será prioritaria al momento de ejecutar los procesos automáticos.'}],
      optionsExcelEmail: [{ id: 'excel_email', text: 'Al marcar esta casilla, la regla puede ser creadas como reporte de excel. (El valor de Canal de Slack se vuelve obligatorio).'}],
      optionsReportCarrier: [{id: 'report', text: 'Reportar al courier'}],
      optionsProgrammedRule: [{id: 'programmed_rule', text: 'Regla programada'}],
      optionsTicketOpening: [{id: 'ticket_opening', text: 'Apertura de tickets'}],
      optionIsNotification: [{id: 'is_notification', text: 'Notificación'}],
      isNotificationRequired: '',
      optionsServiceModes: [
        { id: 'DIS', text: 'Distribución'},
        { id: 'INV', text: 'Logística inversa (Devolución)'},
        { id: 'PAS', text: 'Pasarela'}
      ],
      readOnly: false,
      messageCheckbox: {
        text: 'Al momento de seleccionar este tipo de regla no se efectuara ningún acción o condición de la regla',
        type: 'danger'
      },
      fieldsBase: [],
      viewData: false,
      operatorEdit: {}
    }
  },
  watch: {
    form: {
      handler () {
      },
      deep: true
    }
  },
  mounted () {
    // this.$showOnBottom('.inferior-button', '#form-rule > .card')
    this.initFields()
    setTimeout(() => {
      this.getAllData(this.isEdit ? this.$route.params.id : undefined)
    }, 0)
  },
  methods: {
    setHours () {      
      for (let i = 0; i < 24; i++) {
        for (let j = 0; j < 6; j++) {
          const text = `${this.putZero(i)}:${this.putZero(j * 10)}`
          this.hours.push({ id: text, text})
        }
      }
    },
    putZero (value = 0) {
      const aux = `${value}`
      return aux.length === 1 ? `0${aux}` : aux
    },
    sendForm2 (form) {
      form.hours.map(el => {
        // this.chips.push(`${form.days.text} ${el.id}`)
        const index = this.chips.findIndex(el2 => (el2.day_id === form.days.id && el2.hour === el.id && form.country.id === el2.country_id))
        if (index === -1) this.chips.push({day: form.days.text, day_id: form.days.id, hour: el.id, country_id: form.country.id})
      })
      this.chips = [... new Set(this.chips)].sort((a, b) => `${a.country_id} ${a.day_id} ${a.hour}` > `${b.country_id} ${b.day_id} ${b.hour}` ? 1 : -1)
    },
    removeChip (chip) {
      this.chips = this.chips.filter(el => !(el.day_id === chip.day_id && el.hour === chip.hour && el.country_id === chip.country_id))
    },
    initFields () {
      // this.optionsVariable = this.cards.filter(({parent}) => parent).map(card => ({...card, text: card.title}))

      this.fieldsBase = [
        {fieldType: 'FieldInput', name: 'name', label: 'Nombre regla', placeholder: '', containerClass: 'container-info col-sm-12 col-md-3', validation: 'required'},
        {fieldType: 'FieldSelect', name: 'read', label: 'Nuevo estado de lectura', containerClass: 'container-info col-sm-12 col-md-3', validation: 'required', searchOnType: { nChars: 999}},
        // {fieldType: 'FieldSelect', name: 'status', label: 'Nuevo estado de ticket', containerClass: 'container-info col-sm-12 col-md-3', validation: ''},
        {fieldType: 'FieldSelect', name: 'subStatus', label: 'Nuevo sub estado de ticket', containerClass: 'container-info col-sm-12 col-md-3', validation: '', searchOnType: { nChars: 999}},
        {fieldType: 'FieldSelect', name: 'subject', label: 'Nuevo asunto de ticket', containerClass: 'container-info col-sm-12 col-md-3', validation: '', searchOnType: { nChars: 999}},
        {fieldType: 'FieldInput', name: 'slack_channel', label: 'Nombre de canal de SLACK', containerClass: 'container-info col-sm-12', validation: this.isNotificationRequired},
        {name: 'key-word-slot', useSlot: true, containerClass: 'container-info col-sm-12 text-right mb-md-n1'},
        {fieldType: 'FieldTextarea', name: 'message', label: 'Mensaje', placeholder: '', maxLength: 1000, containerClass: 'container-info col-sm-12', validation: ''},
        // {fieldType: 'FieldSelect', name: 'service_modes', label: 'Modos de servicio', containerClass: 'container-info col-sm-12 pb-2 md:w-1/2', validation: 'required', multiple: true, options: this.optionsServiceModes},
        {fieldType: 'FieldCheckbox', name: 'super', label: '', align: 'v', containerClass: 'container-info col-sm-12', validation: '',
          options: this.optionsSuperRule},
        {fieldType: 'FieldCheckbox', name: 'report', label: '', align: 'v', containerClass: 'container-info col-sm-12', validation: '',
          options: this.optionsReportCarrier},
        {fieldType: 'FieldCheckbox', name: 'programmed_rule', label: '', align: 'v', containerClass: 'container-info col-sm-12', validation: '',
          options: this.optionsProgrammedRule},
        {fieldType: 'FieldCheckbox', name: 'ticket_opening', label: '', align: 'v', containerClass: 'container-info col-sm-12', validation: '',
          options: this.optionsTicketOpening},
        {fieldType: 'FieldCheckbox', name: 'is_notification', label: '', align: 'v', containerClass: 'container-info col-sm-12', validation: '',
          options: this.optionIsNotification, customMessage: {...this.messageCheckbox}},
        {fieldType: 'FieldCheckbox', name: 'excel_email', label: '', align: 'v', containerClass: 'container-info col-sm-12', validation: '',
          options: this.optionsExcelEmail, change: this.slackRequire}
      ]
      this.fieldsFreq = [
        {fieldType: 'FieldSelect', name: 'country', label: 'País', containerClass: 'container-info col-sm-12 col-md-4', validation: 'required', options: [], searchOnType: { nChars: 999} },
        {fieldType: 'FieldSelect', name: 'days', label: 'Frecuencia de ejecución', containerClass: 'container-info col-sm-12 col-md-3', validation: 'required', options: this.days },
        {fieldType: 'FieldSelect', name: 'hours', label: 'Hora', containerClass: 'container-info col-sm-12 col-md-3', validation: 'required', options: this.hours, multiple: true }
      ]
      this.setHours()
      this.keyFormRender++
    },
    slackRequire() {
      this.fieldsBase.map((el) => {
        if (el.name === 'slack_channel') {
          el.validation = this.form.excel_email ? 'required' : ''
        }
      })
    },
    setLoading (bool) {
      if (bool) this.loading = true
      else setTimeout(() => this.loading = false, 500)
    },
    getAllData (id) {
      this.setLoading(true)
      this.loadingSave = true
      const arrServices = [
        {name: 'getTicketSubjects'},
        {name: 'getSubStatuses'},
        {name: 'getTicketStatuses'},
        {name: 'getCountries'},
        {name: 'getConditions'}
      ]
      if (id) {
        arrServices.push({name: 'getRule', params: {id}})
      }
      // Espero todo antes de dejar de cargar
      this.ticketManagementService.callMultipleServices(arrServices, true)
        .then(response => {
          this.ticketSubjects = [{id:'', text: 'Mantener asunto'}].concat(this.ticketManagementService.formatSelect(response.getTicketSubjects.data, 'ticket_type'))
          this.ticketStatuses = [{id:'', text: 'Mantener estado'}].concat(this.ticketManagementService.formatSelect(response.getTicketStatuses.data, 'ticket_status'))
          this.ticketSubStatuses = [{id:'', text: 'Mantener estado'}].concat(this.ticketManagementService.formatSelect(response.getSubStatuses.data, 'ticket_sub_status'))
          this.readStatus = [{id:'', text: 'Mantener estado de lectura'}, {id: 1, text: 'Leído'}, {id: 2, text: 'No leído'}]
          this.countries = response.getCountries.data.map(el => ({...el, id: el.code, text: el.name}))

          this.form.subject = this.ticketSubjects[0]
          this.form.status = this.ticketStatuses[0]
          this.form.subStatus = this.ticketSubStatuses[0]
          this.form.read = this.readStatus[0]

          const indexSubject = this.fieldsBase.findIndex(({name}) => name === 'subject')
          // const indexStatus = this.fieldsBase.findIndex(({name}) => name === 'status')
          const indexSubStatus = this.fieldsBase.findIndex(({name}) => name === 'subStatus')
          const indexRead = this.fieldsBase.findIndex(({name}) => name === 'read')
          const indexCountry = this.fieldsFreq.findIndex(({name}) => name === 'country')
          this.fieldsBase[indexSubject].options = this.ticketSubjects
          // this.fieldsBase[indexStatus].options = this.ticketStatuses
          this.fieldsBase[indexSubStatus].options = this.ticketSubStatuses
          this.fieldsBase[indexRead].options = this.readStatus
          this.fieldsFreq[indexCountry].options = this.countries
          this.cards = response.getConditions.data
          this.optionsVariable = response.getConditions.data.filter(({parent}) => parent) ? response.getConditions.data.filter(({parent}) => parent).map(card => ({...card, text: card.title})) : []
          if (id) {
            this.setDataRule(response.getRule.data)
          } else {
            this.setLoading(false)
          }
          this.viewData = true
          this.keyFormRender++
        })
        .catch(err => {
          console.error(err)
          const text = !id ? 'Ocurrió un problema al cargar los estados y asuntos de tickets' : 'Ocurrió un problema al cargar los datos de la regla'
          this.$alert(text)
        })
        .finally(end => {
          this.loadingSave = false
        })
    },
    setDataRule (data) {
      // Seteo de 4 datos base
      const indexSubject = this.ticketSubjects.findIndex(({code}) => code === data.ticket_type)
      const indexStatus = this.ticketStatuses.findIndex(({code}) => code === data.ticket_status)
      const indexSubStatus = this.ticketSubStatuses.findIndex(({code}) => code === data.ticket_sub_status)
      // const indexSuper = this.optionsSuperRule.findIndex(({id}) => id === 'super' && data.super)
      // const indexReport = this.optionsReportCarrier.findIndex(({id}) => id === 'report' && data.report_to_carrier)
      // const indexTicketOpening = this.optionsTicketOpening.findIndex(({id}) => id === 'ticket_opening' && data.ticket_opening)
      // const indexIsNotification = this.optionIsNotification.findIndex(({id}) => id === 'is_notification' && data.is_notification)
      const indexRead = this.readStatus.findIndex(({id}) => (data.read_agent === null ? '' : data.read_agent === true ? 1 : 2) === id)
      this.readOnly = data.read_only === 1
      this.form = {
        name: data.name,
        slack_channel: data.slack_channel,
        message: data.message,
        description: data.description,
        subject: indexSubject === -1 ? this.form.subject : this.ticketSubjects[indexSubject],
        status: indexStatus === -1 ? this.form.status : this.ticketStatuses[indexStatus],
        subStatus: indexSubStatus === -1 ? this.form.sub_status : this.ticketSubStatuses[indexSubStatus],
        read: indexRead === -1 ? this.form.read : this.readStatus[indexRead],
        super: !!data.super,
        excel_email: !!data.excel_email,
        // service_modes: data.service_modes ? this.optionsServiceModes.filter(el => data.service_modes.includes(el.id)) : [],
        // report: indexReport === -1 ? [] : [this.optionsReportCarrier[indexReport]],
        report: !!data.report_to_carrier,
        programmed_rule: !!data.execution_frequency,
        ticket_opening: !!data.ticket_opening,
        is_notification: !!data.is_notification
      }
      if (!!data.execution_frequency && data.execution_frequency.length) {
        data.execution_frequency.map(ex => {
          if (ex.frequencies) {
            ex.frequencies.map(f => {
              const indexDay = this.days.findIndex(el => f.day === el.id)
              if (indexDay !== -1) this.chips.push({day: this.days[indexDay].text, day_id: f.day, hour: f.time, country_id: ex.country})
            })
          }
        })
      }
      this.generateConditions(data.conditions)
    },
    generateConditions (conditions) {
      const listConditions = []
      if (conditions.length > 0) {
        conditions.map(condition => {
          const variable = this.cards.filter(({id}) => id === condition.variable)[0]
          const operator = operators.filter(({id}) => id === condition.operator)[0]
          const last_user = lastUserList.filter(({id}) => id === condition.last_user)[0]
          if (variable && operator) {
            listConditions.push({
              variable: variable || null,
              operator: {...operator, textOperator: this.ticketManagementService.getTextOperator(operator.id)} || null,
              last_user:  {...last_user, textLastUser: (last_user && last_user.id !== '' ? last_user.text.toLowerCase() : '')},
              group: { id: '', text: '--', values: condition.values },
              valueGroup: {id: -1, values: condition.values, typeof_value: this.ticketManagementService.validateNullVariable({type: variable.type, values: condition.values}), variable: variable.id}
            })
          }
        })
        listConditions.map(condition => {
          condition = {...this.getTextValues(condition)}
          return condition
        })
        this.conditions = [...listConditions]
        this.setLoading(false)
      } else {
        this.setLoading(false)
      }
    },
    getTextValues (condition) {
      if (!!condition.variable.fx) {
        return this.getListGroup(condition)
      } else {
        condition.valueGroup.text_values = condition.valueGroup.values
        this.conditionsLoaded++
        if (this.conditionsLoaded === this.conditions.length) this.setLoading(false)
        return condition
      }
    },
    getListGroup (condition) {
      if (condition.variable.fx) {
        this.ticketManagementService.callService(condition.variable.fx, {limit:9999, page:1})
          .then(response => {
            const listValues = this.ticketManagementService.formatSelect(response.data, condition.variable.id)
            condition.valueGroup.text_values = condition.valueGroup.values.map(val => {
              const indexElement = listValues.findIndex(element => element.id === val)
              return indexElement !== -1 ? listValues[indexElement].text : val
            })
          })
          .catch(err => {
            console.error(err)
            this.$alert(`Ocurrió un problema al cargar la lista de ${condition.variable.title.toLowerCase()}`)
          })
          .finally(end => {
            this.conditionsLoaded++
            if (this.conditionsLoaded === this.conditions.length) this.setLoading(false)
            return condition
          })
      } else if (condition.variable.data) {
        const listValues = condition.variable.data
        condition.valueGroup.text_values = condition.valueGroup.values.map(val => {
          const indexElement = listValues.findIndex(element => element.id === val)
          return indexElement !== -1 ? listValues[indexElement].text : val
        })
      } else if (['commune', 'commune_drop'].includes(condition.variable.id)) {
        const listValues = this.ticketManagementService.formatSelect(comunas, condition.variable.id)
        condition.valueGroup.text_values = condition.valueGroup.values.map(val => {
          const indexElement = listValues.findIndex(element => element.id === val)
          return indexElement !== -1 ? listValues[indexElement].text : val
        })
      }
    },
    addCombination () {
      // Añade los fields para nueva combinación
      const auxConditions = [...this.conditions]
      if (this.currentIndexGroup !== -1) {
        auxConditions[this.currentIndexGroup] = {
          variable: this.form.variable,
          operator: this.form.operator,
          group: this.form.group,
          last_user: this.form.last_user,
          valueGroup: this.form.valueGroup
        }
        if (!this.conditions[this.currentIndexGroup]._new) {
          auxConditions[this.currentIndexGroup]._edited = true
        } else {
          auxConditions[this.currentIndexGroup]._new = true
        }
        this.currentIndexGroup = -1
      } else {
        auxConditions.push({
          variable: this.form.variable,
          operator: this.form.operator,
          group: this.form.group,
          last_user: this.form.last_user,
          valueGroup: this.form.valueGroup,
          _new: true
        })
      }
      this.form.variable = undefined
      this.form.operator = undefined
      this.form.group = undefined
      this.form.last_user = undefined
      this.conditions = [...auxConditions]
      // this.keyConditionList++
    },
    removeCondition (index) {
      const auxConditions = [...this.conditions]
      // delete auxConditions[index]._edited
      // delete auxConditions[index]._new
      auxConditions[index]._deleted = true
      this.conditions = [...auxConditions]
      // this.keyConditionList++
    },
    editCondition (index) {
      const auxConditions = [...this.conditions].map(condition => {
        delete condition.editing
        return condition
      })
      this.currentIndexGroup = index
      delete auxConditions[index].editing
      this.form = {...this.form, ...auxConditions[index]}
      auxConditions[index].editing = true
      this.operatorEdit = auxConditions[index]
      this.conditions = [...auxConditions]
    },
    cancelEdit (index) {
      const auxConditions = [...this.conditions]
      this.currentIndexGroup = -1
      delete auxConditions[index].editing
      this.form.variable = undefined
      this.form.operator = undefined
      this.form.group = undefined
      this.form.last_user = undefined
      this.conditions = [...auxConditions]
    },
    cancelDelete (index) {
      const auxConditions = [...this.conditions]
      delete auxConditions[index]._deleted
      this.conditions = [...auxConditions]
    },
    setOptionsGroup (groups, indexField, variable) {
      this.fields[indexField].options = groups.filter(group => {
        return group.variable === variable
      })
    },
    getConditionsPayload () {
      return this.conditions.filter(condition => !condition._deleted).map(condition => {
        return {
          variable: condition.variable.id,
          operator: condition.operator.id,
          values: condition.group.values,
          last_user: condition.last_user ? condition.last_user.id : null
        }
      })
    },
    confirmDelete () {
      this.$yesno('¿Está seguro que desea eliminar esta regla?', (_) => this.deleteRule(this.$route.params.id))
    },
    deleteRule (id) {
      this.ticketManagementService.callService('deleteRule', null, {id})
        .then(response => {
          this.$success('La regla ha sido eliminada correctamente')
          // this.$router.push({name: 'ticket-rules'})
          location.reload()
        })
        .catch(err => {
          console.error(err)
          this.$alert('Ocurrió un problema al eliminar la regla')
        })
        .finally(end => {
          // this.setLoading(false)
        })
    },
    checkRule () {
      this.$refs.formRenderRule.checkForm()
    },
    getFrequency () {
      const myFreq = []
      if (this.form.programmed_rule) {
        this.chips.map(el => {
          let indexCountry = myFreq.findIndex(mf => mf.country === el.country_id)
          if (indexCountry === -1) {
            myFreq.push({country: el.country_id, frequencies: []})
            indexCountry = myFreq.findIndex(mf => mf.country === el.country_id)
          }
          myFreq[indexCountry].frequencies.push({day: el.day_id, time: el.hour})
        })
      }
      return myFreq.length === 0 ? null : myFreq
    },
    sendForm (form) {
      const payload = {
        name: this.form.name,
        slack_channel: this.form.slack_channel,
        activated: true,
        ticket_status: this.form.subStatus && this.form.subStatus.id && this.form.subStatus.id !== '' ? this.form.subStatus.ticket_status_code : null,
        ticket_sub_status: this.form.subStatus && this.form.subStatus.id && this.form.subStatus.id !== '' ? this.form.subStatus.id : null,
        ticket_type: this.form.subject && this.form.subject.id && this.form.subject.id !== '' ? this.form.subject.id : null,
        read_agent: this.form.read && this.form.read.id && this.form.read.id !== '' ? this.form.read.id === 1 : null,
        message: this.form.message || null,
        super: !!this.form.super,
        excel_email: !!this.form.excel_email,
        report_to_carrier: !!this.form.report,
        // service_modes: this.form.service_modes && this.form.service_modes.length > 0 ? this.form.service_modes.map(el => el.id) : [],
        description: '',
        conditions: this.getConditionsPayload(),
        ticket_opening: !!this.form.ticket_opening,
        is_notification: !!this.form.is_notification
      }
      const freq = this.getFrequency()
      if (!!freq) payload.execution_frequency = freq
      if (this.validatePayload(payload)) this.sendRulePayload(payload)
    },
    validatePayload (payload) {
      if (!payload.name) {
        this.$alert('La regla debe tener un nombre', null, 'Datos incompletos')
        return false
      }
      if (payload.is_notification && !payload.slack_channel) {
        this.$alert('Debes agregar un nombre de canal de Slack')
        return false
      }
      if (!payload.message && !payload.ticket_status && !payload.ticket_type && !payload.ticket_sub_status && !payload.is_notification) {
        this.$alert('Debe completar al menos uno de los campos "Nuevo estado de ticket", "Nuevo asunto de ticket", "Mensaje" o debe ser tipo notificación para guardar la regla', null, 'Datos incompletos')
        return false
      }
      if (!!this.form.variable) {
        this.$confirm('Si guarda ahora, la condición incompleta no será almacenada. ¿Está seguro que desea guardar?', () => this.sendRulePayload(payload), 'Condición incompleta')
        return false
      }
      if (payload.conditions.length === 0) {
        this.$alert('Debe ingresar al menos una condición para guardar la regla', null, 'Sin condiciones')
        return false
      }
      if (payload.conditions.findIndex(el => el.variable === 'country') === -1) {
        this.$alert('La regla debe incluir una condición por país', null, 'Condición por país')
        return false
      } else if (payload.conditions.length === 1) {
        this.$alert('Debe ingresar al menos una condición diferente de país para guardar la regla', null, 'Sin condiciones aplicables')
        return false
      }
      return true
    },
    sendRulePayload (payload) {
      this.$emit('result', payload)
    },
    ruleHasConditions () {
      return !(this.conditions.length === 0 || this.conditions.filter(({_deleted}) => !!_deleted).length === this.conditions.length)
    },
    openModalKeyWords () {
      this.openModal = true
    },
    addWordTextbox (word) {
      this.form.message = `${this.form.message || ''} ${word.key}`
      this.keyFormRender++
    }
  }
}
</script>

<style lang="scss">
#form-group.vs-con-loading__container{
  overflow: inherit !important;
}
.container-title{
  position: relative;
  > .title {
    float: left !important;
    position: absolute;
    bottom: 0
  }
}
.combination:hover {
  .remove {display:block;}
}
.remove {
  display: none;
  padding: 5px 8px;
  position: absolute;
  right: -5px;
  top: -5px;
  background: red !important;
  &:hover {
    color: white;
    background: #ff7676 !important;
  }
}
</style>
